import { Component, OnInit } from '@angular/core';
import {faCog, faDoorOpen, faKey, faListAlt, faStickyNote} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ChangePasswordDialogComponent} from '../modules/common-ui/change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  faKey = faKey;
  faCog = faCog;
  faDoorOpen = faDoorOpen;
  faStickyNote = faStickyNote;
  faListAlt = faListAlt;
  currentUser: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
  ) {
    this.currentUser = JSON.parse(sessionStorage.getItem("user"));
  }

  ngOnInit(): void {
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  changePassword(): void {
    const config = new MatDialogConfig();
    config.autoFocus = true;
    config.disableClose = true;
    this.dialog.open(ChangePasswordDialogComponent, config);
  }
}
