// Official dependencies
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { MatBadgeModule } from '@angular/material/badge';
import {
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from '@angular/material/tabs';

// Routing
import { AppRoutingModule } from "./app-routing.module";

// Others
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MasterModelModule } from "./modules/master-model/master-model.module";
import { LocalStorage } from "./utils/dataStore";

// Components
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { MessageBoxComponent } from "./message-box/message-box.component";
import { SettingsComponent } from "./settings/settings.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UserInfoComponent } from "./user-info/user-info.component";
import { LoginComponent } from "./login/login.component";
import { BusyIndicatorComponent } from "./utilities/busy-indicator/busy-indicator.component";
import { CommonUiModule } from "./modules/common-ui/common-ui.module";
//  { StockTicketListComponent } from './desktop/stock-ticket-list/stock-ticket-list.component';
// import { PcHomeComponent } from './desktop/pc-home/pc-home.component';

// Service
import { TokenInterceptor } from "./utilities/token-interceptor";
import { LocationDialogComponent } from "./modules/common-ui/location-dialog/location-dialog.component";
import { ConfigService } from "./utilities/config.service";
import { ChangePasswordDialogComponent } from "./modules/common-ui/change-password-dialog/change-password-dialog.component";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { ButtonRendererComponent } from "./modules/common-ui/button-renderer/button-renderer.component";
import { ButtonPrintRendererComponent } from "./modules/common-ui/button-print-renderer/button-print-renderer.component";
import { ConfirmationDialogComponent } from "./modules/common-ui/confirmation-dialog/confirmation-dialog.component";
import { RouteReuseStrategy } from "@angular/router";
import { ZwRouteReuseStrategy } from "./modules/warehouse/ticket-iqc/ticket-iqc-state/zw-route-reuse-strategy";

// Modules

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MessageBoxComponent,
    SettingsComponent,
    DashboardComponent,
    UserInfoComponent,
    LoginComponent,
    BusyIndicatorComponent,
    //    StockTicketListComponent,
    //    PcHomeComponent
  ],
  imports: [
    MasterModelModule,
    HttpClientModule,
    CdkTableModule,
    CdkTreeModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatListModule,
    FontAwesomeModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonUiModule,
    MatMomentDateModule,
    MatSnackBarModule,
    MatTabsModule,
    MatBadgeModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: ZwRouteReuseStrategy,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: "zh-CN" },
    { provide: Window, useValue: window },
    LocalStorage,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MessageBoxComponent,
    LocationDialogComponent,
    ChangePasswordDialogComponent,
    ButtonRendererComponent,
    ButtonPrintRendererComponent,
    ConfirmationDialogComponent,
  ],
  exports: [],
})
export class AppModule {
  constructor(private config: ConfigService) {}
}
