import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public baseUrl = '';
  public isMobile = false;

  constructor(
    private http: HttpClient
  ) {
    // 导入服务器 config.json 配置档
    this.http.get('assets/config.json')
      .pipe(map(data => {
        this.baseUrl = data['baseUrl'];
        sessionStorage.setItem('baseUrl', this.baseUrl);
      })).toPromise();

    // 判断设备画面宽度
    if (window.screen.width < 800) {
      this.isMobile = true;
      sessionStorage.setItem('isMobile', 'true');
    }
  }

}
