import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { SirenService } from '../utilities/siren.service';
import { BusyService } from '../utilities/busy.service';
import { ActivatedRoute } from '@angular/router';
import { NfcService } from '../utilities/nfc.service';
import { ConfigService } from '../utilities/config.service';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  subscription = [];
  RFID_timer: Subscription;

  constructor(
    private nfcService: NfcService,
    private dialog: MatDialog,
    private zone: NgZone,
    public authService: AuthService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private siren: SirenService,
    private busyService: BusyService,
    private config: ConfigService,
    private window: Window) {
    const sub = nfcService.nfcID.subscribe((val) => {
      if (activatedRoute.snapshot.url[0].path === 'login') {
        // 由于 BehaviorSubject 在订阅那一刻会把当前值先发送一次，这部分是检查赋值时间和接收时间，如果超过
        // 一百毫秒则当作旧值，不做处理
        const now = +new Date();
        if (now - val.time < 100) {
          this.zone.run(() => {
            this.nfcLogin(val.value);
          });
        }
      }
    });
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      'custCode': new FormControl({ value: 'XH', disabled: true }, [Validators.required]),
      'userName': new FormControl('', [Validators.required]),
      'password': new FormControl('', [Validators.required]),
      'rememberMe': new FormControl('')
    });

    this.rememberMe.setValue(false);

    // Test new PDA
    // const timer = interval(6000);
    // this.RFID_timer = timer.subscribe(() => {this.window['ANDROID'].startCardReader(); });
    try {
      this.window['ANDROID'].startCardReader();
    } catch (e) {
      console.log(e.message);
    }
  }

  get userName() { return this.loginForm.get('userName'); }
  get password() { return this.loginForm.get('password'); }
  get custCode() { return this.loginForm.get('custCode'); }
  get rememberMe() { return this.loginForm.get('rememberMe'); }

  login(): void {
    this.busyService.setLoading(true);
    this.authService.login(this.custCode.value, this.userName.value, this.password.value)
      .subscribe(() => {
        if (this.authService.isLoggedIn) {
          this.busyService.setLoading(false);
          const landingPage = this.config.isMobile ? '/core/dashboard' : '/desktop/dashboard';
          const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : landingPage;
          // fix js will throw error once reload and login
          this.router.navigateByUrl(redirect);
        }

        /*else {
          this.busyService.setLoading(false);
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data = { title: '错误', text: '用户名或密码错误'};
          this.dialog.open(MessageBoxComponent, dialogConfig);
        }*/
      }, err => {
        this.busyService.setLoading(false);
        this.siren.beep();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        if (err.status === 401) {
          dialogConfig.data = { title: '错误', text: '用户名或密码错误' };
        } else {
          dialogConfig.data = { title: '错误', text: '连接中断' };
        }
        console.log(err.message);
        this.dialog.open(MessageBoxComponent, dialogConfig);
      });
  }

  nfcLogin(tagText): void {


    this.busyService.setLoading(true);
    this.authService.nfcLogin(tagText)
      .subscribe(() => {
        if (!this.authService.isLoggedIn) {
          this.authService.isLoggedIn = true;
          // this.busyService.setLoading(false);
          const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/core/dashboard';
          this.router.navigate([redirect]);
        }
        this.busyService.setLoading(false);
      }, err => {
        this.busyService.setLoading(false);
        this.siren.beep();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.data = { title: '错误', text: err.error.message };
        this.dialog.open(MessageBoxComponent, dialogConfig);
      }
      );
  }

  // Test new PDA
  ngOnDestroy(): void {
    // this.RFID_timer.unsubscribe();
    try {
      this.window['ANDROID'].stopCardReader();
    } catch (e) {
      console.log(e.message);
    }
  }
}
