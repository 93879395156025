import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
// import {ICellRendererParams} from 'ag-grid-enterprise';

@Component({
  selector: 'app-button-renderer',
  template: '<button class="{{btnClass}}" type="button" (click)="onClick($event)">{{label}}</button>',
})
export class ButtonRendererComponent implements ICellRendererAngularComp {

  params: any;
  label: string;
  getLabelFunction: any;
  btnClass: string;

  constructor() { }

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    this.btnClass = this.params.btnClass || 'mat-button';
    this.getLabelFunction = this.params.getLabelFunction;
    if (this.getLabelFunction && this.getLabelFunction instanceof Function) {
      this.label = this.getLabelFunction(params.data);
    }
  }

  refresh(params: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data,
      };
      this.params.onClick(params);
    }
  }

}
