/**
 * Created by Hua on 21/9/2018.
 */
import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SettingsComponent } from './settings/settings.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserInfoComponent } from './user-info/user-info.component';
import {SettingModule} from './modules/setting/setting.module';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';

export const ROUTES: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },

  {
    path: 'desktop',
    canActivate: [AuthGuard],
    loadChildren: () => import('./desktop/desktop.module').then(m => m.DesktopModule)},

  {
    path: 'core',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'settings', component: SettingsComponent },
      { path: 'user-info', component: UserInfoComponent },
      { path: 'dashboard', component: DashboardComponent},
      {
        path: 'msg',
        loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule)
      },
      {
        path: 'purchasing',
        loadChildren: () => import('./modules/purchasing/purchasing.module').then(m => m.PurchasingModule)
      },
      {
        path: 'warehouse',
        loadChildren: () => import('./modules/warehouse/warehouse.module').then(m => m.WarehouseModule)
      },
      {
        path: 'production',
        loadChildren: () => import('./modules/production/production.module').then(m => m.ProductionModule)
      },
      {
        path: 'sales',
        loadChildren: () => import('./modules/sales/sales.module').then(m => m.SalesModule)
      },
      {
        path: 'stocktake',
        loadChildren: () => import('./modules/stocktake/stocktake.module').then(m => m.StocktakeModule)
      },
      {
        path: 'setting',
        loadChildren: () => import('./modules/setting/setting.module').then(m => m.SettingModule)
      },
    ]
  }
];
