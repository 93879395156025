<h2 mat-dialog-title>修改密码</h2>
<mat-dialog-content>
  <form [formGroup]="changeForm">
    <mat-form-field hideRequiredMarker>
      <input matInput placeholder="新密码" type="password" formControlName="password" required>
      <mat-error *ngIf="password.invalid && (password.dirty || password.touched)">新密码必填</mat-error>
    </mat-form-field>
    <br/>
    <mat-form-field hideRequiredMarker>
      <input matInput placeholder="确认新密码" type="password" formControlName="confirm" required>
      <mat-error *ngIf="confirm.invalid && (confirm.dirty || confirm.touched)">值需与新密码相同</mat-error>
    </mat-form-field>
    <br/>
    <div class="button-area">
      <button type="submit" mat-button [disabled]="!changeForm.valid" (click)="save()">保存</button>
      <button mat-button color="warn" (click)="cancel()">取消</button>
    </div>
  </form>
</mat-dialog-content>
