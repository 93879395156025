import { Component } from '@angular/core';
// import { AlertComponent} from './alert/alert.component';
// import { BarcodeService } from './utilities/barcode.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'WMS';

  constructor() {
  }
}
