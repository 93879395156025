import { Injectable } from '@angular/core';

interface WebAppInterface {
  playAlert(): any;
}
declare var ANDROID: WebAppInterface;

@Injectable({
  providedIn: 'root'
})
export class SirenService {

  beep(): void {
    if (typeof ANDROID !== 'undefined') {
      ANDROID.playAlert();
    }
  }

  constructor() { }
}
