<h2 mat-dialog-title>货位</h2>

<mat-dialog-content [formGroup]="form">

  <mat-form-field>
    <input matInput
           placeholder="输入或扫描货位"
           formControlName="locationCode"
           oninput="value=value.replace(/[^\a-\z\A-\Z0-9\-]/g,'')">
  </mat-form-field>
  <div class="mat-error" *ngIf="showError">{{errorMessage}}</div>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="mat-raised-button"(click)="close()">关闭</button>
  <button class="mat-raised-button mat-primary"(click)="save()">保存</button>
</mat-dialog-actions>
