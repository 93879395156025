/**
 * Created by Hua on 22/10/2018.
 */
import {PurStockInDetails} from './PurStockInDetails';
import {PurStockInGroupedDetails} from './PurStockInGroupedDetails';

/**
 * 采购入库单表头
 */
export class PurStockIn {
  /**
   * 货位编码
   */
  positionCode = '';
  ticketCode = '';
  /**
   * 已扫描的标签集合
   */
  details: PurStockInDetails[];

  /**
   * 按物料分组集合
   */
  groupedDetails: PurStockInGroupedDetails[];

  constructor() {
    this.details = [];
    this.groupedDetails = [];
  }
}
