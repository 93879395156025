<div class="top-sidenav-container sidenav-is-mobile">
  <mat-toolbar class="topnav-toolbar">
    <div><a (click)="backCmd()" class="icon-center">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
      </a>
      <img *ngIf="topNavImgSrc" [src]="topNavImgSrc" />
      <h4 class="topnav-title">{{titleText}}</h4>
    </div>
    <ng-content class="right"></ng-content>
  </mat-toolbar>
</div>
