import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs';
import { IStkOrganization, StkOrganizationService } from 'src/app/common/stk-organization.service';

@Component({
  selector: 'app-org-select',
  templateUrl: './org-select.component.html',
  styles: ['.mat-form-field{width:99%}']
})
export class OrgSelectComponent implements OnInit {

  /** 组织代码 空代码所有 */
  @Input() orgCode = '';
  /** 组织列表 */
  orgs: Observable<IStkOrganization[]>;

  @Output() selectionChange = new EventEmitter<string>();

  constructor(private stkOrgService: StkOrganizationService) { }

  ngOnInit(): void {
    this.orgs = this.stkOrgService.getOrganizations();
  }

  onOrgSelectionChange(selection: MatSelectChange) {
    this.selectionChange.emit(selection.value);
  }
}
