import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

class CompareValidator {
  static match(targetField: string): ValidatorFn {
    return (self: AbstractControl): {[key: string]: any} => {
      const form = self.parent;
      if (form) {
        const targetControl: AbstractControl = form.controls[targetField];
        if (targetControl.value && self.value !== targetControl.value) {
          return {match: ''};
        }
      }
    };
  }
}

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {
  title: string;
  changeForm: FormGroup;
  change = {
    password: '',
    confirm: '',
  };

  get password() { return this.changeForm.get('password'); }
  get confirm() { return this.changeForm.get('confirm'); }

  constructor(
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private http: HttpClient,
    ) {
  }

  ngOnInit() {
    this.changeForm = new FormGroup({
      'password': new FormControl('', [
        Validators.required
      ]),
      'confirm': new FormControl('', [
        Validators.required,
        CompareValidator.match('password'),
      ]),
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  async save() {
    const baseUrl = sessionStorage.getItem('baseUrl');
    try {
      const result = await this.http.post(
        baseUrl + '/users/changePassword',
        this.changeForm.value,
      ).toPromise();
    } catch (e) {
      console.log(e.message);
      throw e;
    }
    this.dialogRef.close();
  }

}
