import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  faExchangeAlt,
  faHome,
  faLuggageCart,
  faBox,
  faSync,
  faTruckLoading,
  faBoxOpen,
  faCity,
  faSearch,
  faStickyNote,
  faPrint,
  faFire,
  faCoffee,
  faInbox,
  faSearchPlus,
  faClock,
  faTruck,
  faTools,
  faWaveSquare,
  faDoorOpen,
  faRetweet,
  faStamp,
  faCheckSquare,
  faUserCheck,
  faRedo,
  faCube,
  faDesktop,
  faFileSignature
} from "@fortawesome/free-solid-svg-icons";
import MenuList from "../utils/menuList";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalStorage } from "../utils/dataStore";
import MenuListHT from "../utils/menuListHT";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  faRetweet = faRetweet;
  faCity = faCity;
  faTruckLoading = faTruckLoading;
  faLuggageCart = faLuggageCart;
  faSync = faSync;
  faExchangeAlt = faExchangeAlt;
  faBox = faBox;
  faBoxOpen = faBoxOpen;
  faSearch = faSearch;
  faStickyNote = faStickyNote;
  faPrint = faPrint;
  faFile = faFire;
  faCoffee = faCoffee;
  faInbox = faInbox;
  faSearchPlus = faSearchPlus;
  faClock = faClock;
  faDoorOpen = faDoorOpen;
  faTruck = faTruck;
  faWaveSquare = faWaveSquare;
  faTools = faTools;
  faStamp = faStamp; //盖章
  faCheckSquare = faCheckSquare; //签收
  faUserCheck = faUserCheck;
  faRedo = faRedo;
  faCube = faCube;
  faDesktop = faDesktop;

  roleId = [];
  menuIconList = [];
  menuList = {};
  showList = [];
  showListHT = [];
  menuListHT = {};
  messageCountXH = 0;
  messageCountHT = 0;

  dbCode = 1; //0默认选择第一个选项卡  1默认选择第二个选项卡

  matBadgeXH = [
    {
      title: "原材料运输",
      key: "materialTransport",
      msgCount: 0,
    },
    {
      title: "原材料搬运-退货",
      key: "materialReturnGoods",
      msgCount: 0,
    },
    {
      title: "原材料货位转移",
      key: "materialMove",
      msgCount: 0,
    },
    {
      title: "出库申请单",
      key: "auxMaterialOutStorage",
      msgCount: 0,
    },
    {
      title: "半成品出库",
      key: "loosePartOutStorage",
      msgCount: 0,
    },
    {
      title: "辅料签收确认",
      key: "auxMaterialSign",
      msgCount: 0,
    },
    {
      title: "散件签收确认",
      key: "loosePartSign",
      msgCount: 0,
    },
    {
      title: "原材料签收确认",
      key: "materialSign",
      msgCount: 0,
    },
    {
      title: "辅料运输任务",
      key: "auxMaterialTransport",
      msgCount: 0,
    },
  ];

  matBadgeHT = [
    {
      title: "原材料运输",
      key: "materialTransport",
      msgCount: 0,
    },
    {
      title: "原材料搬运-退货",
      key: "materialReturnGoods",
      msgCount: 0,
    },
    {
      title: "原材料货位转移",
      key: "materialMove",
      msgCount: 0,
    },
    {
      title: "出库申请单",
      key: "auxMaterialOutStorage",
      msgCount: 0,
    },
    {
      title: "半成品出库",
      key: "loosePartOutStorage",
      msgCount: 0,
    },
    {
      title: "辅料签收确认",
      key: "auxMaterialSign",
      msgCount: 0,
    },
    {
      title: "散件签收确认",
      key: "loosePartSign",
      msgCount: 0,
    },
    {
      title: "原材料签收确认",
      key: "materialSign",
      msgCount: 0,
    },
    {
      title: "辅料运输任务",
      key: "auxMaterialTransport",
      msgCount: 0,
    },
    {
      title: "散件运输任务",
      key: "loosePartTransport",
      msgCount: 0,
    },
  ];

  order = [
    "销售退货",
    "EOL调拨",
    "物料退仓",
    "库存盘点",
    "跨组织调拨",
    "跨组织调拨搬运任务",
    "货位转移",
    "收料通知",
    "采购订单入库",
    "采购入库",
    "采购入库-打印卡片",
    "IQC",
    "散件-IQC",
    "原材料货位转移",
    "原材料搬运-退货",
    "生产领料",
    "半成品出库",
    "出库申请单",
    "成品出库",
    "嗦扶咀回收",
    "成品出库",
    "原材料运输",
    "辅料运输任务",
    "散件运输任务",
    "成品运输任务",
    "原材料签收确认",
    "辅料签收确认",
    "散件签收确认",
    "成品签收确认",
    "成品入库待签收",
    "成品入库搬运",
    "成品签收确认",
    "补打贴纸",
    "标签禁用",
    "标签查询",
    "金蝶查询",
    "收发明细查询",
    "标签激活",
    "仓库转移",
    "保税信息",
    "汇总码打印",
    "超时未签收明细",
    // "物料退仓",
    "采购收货",
    "采购退货",
    "TMS",
    "其他出库",
    "标签维护",
    "生产退料",
    "生产完工",
    "成品退回",
    "销售发货",
    // "销售退货",
    "盘点现况",
    "盘点控制台",
    "量具-IQC",
    "查询货物库存",
  ];

  constructor(private http: HttpClient, private localStorage: LocalStorage) {
    this.menuList = MenuList;
    this.menuListHT = MenuListHT;
  }

  ngOnInit() {
    if (sessionStorage.getItem("db-code") !== "HT") {
      //dbCode=0时为新豪
      this.dbCode = 0;
    }
    this.getTaskNum(); //查消息数量
    // this.getNews();

    this.roleId = JSON.parse(sessionStorage.getItem("roleId"));
    this.menuIconList = JSON.parse(sessionStorage.getItem("menuIconList"));
    if (this.menuIconList && this.menuIconList.length > 0) {
      this.showList = this.menuIconList.filter((item) => {
        item.url = item.routeUrl;
        if (item.type && item.type != 0 || item.type != '0') {
          item.queryParams = JSON.parse(item.type);
        }
        return item.orgCode == 1;
      });
      this.showListHT = this.menuIconList.filter((item) => {
        item.url = item.routeUrl;
        if (item.type && (item.type != 0 || item.type != '0')) {
          item.queryParams = JSON.parse(item.type);
        }
        return item.orgCode == 2;
      });

    }
    // this.roleId.map((item) => {
    //   for (let key in this.menuList) {
    //     if (key == item) {
    //       this.showList.push(...this.menuList[key]);
    //     }
    //   }
    //   for (let key in this.menuListHT) {
    //     if (key == item) {
    //       this.showListHT.push(...this.menuListHT[key]);
    //     }
    //   }
    // });
    // let map = new Map();
    // for (const item of this.showList) {
    //   if (!map.has(item.title)) {
    //     map.set(item.title, item);
    //   }
    // }
    // this.showList = Array.from(map.values());
    // // console.log(this.showList);
    // let mapHT = new Map();
    // for (const item of this.showListHT) {
    //   if (!mapHT.has(item.item)) {
    //     mapHT.set(item.title, item);
    //   }
    // }
    // this.showListHT = Array.from(mapHT.values());
    // console.log(this.showListHT);

    // const obj = {
    //   title: "库存盘点",
    //   icon: "faCity",
    //   url: "../stocktake/stocktateInventory",
    // };
    // this.showList.push(obj);
    // this.showListHT.push(obj);
    console.log('新豪结果==》',this.showList)
    //菜单按照order的顺序进行排序
    this.showList = this.showList
      // .filter((item) => this.order.includes(item.title))
      .sort((a, b) => {
        const indexA = this.order.indexOf(a.title);
        const indexB = this.order.indexOf(b.title);
        return indexA - indexB;
      });
    this.showListHT = this.showListHT
      // .filter((item) => this.order.includes(item.title))
      .sort((a, b) => {
        const indexA = this.order.indexOf(a.title);
        const indexB = this.order.indexOf(b.title);
        return indexA - indexB;
      });
  }

  changeTab(e) {
    sessionStorage.setItem("db-code", e["tab"]["textLabel"]);
    if (e["tab"]["textLabel"] == "XH") {
      this.getTaskNum();
    } else if (e["tab"]["textLabel"] == "HT") {
      this.getTaskNum();
    }
  }

  async getTaskNum() {
    this.messageCountXH = 0;
    this.messageCountHT = 0;
    try {
      const result = await this.http
        .get(sessionStorage.getItem("baseUrl") + "/statistics/getTaskNum")
        .toPromise();
      console.log(result);
      const data = result["data"].XH;
      const dataHT = result["data"].HT;

      this.matBadgeXH.map((item) => {
        for (const key in data) {
          if (key == item.key) {
            item.msgCount = data[key];
            this.messageCountXH += data[key];
          }
        }
      });
      this.matBadgeHT.map((item) => {
        for (const key in dataHT) {
          if (key == item.key) {
            item.msgCount = dataHT[key];
            this.messageCountHT += dataHT[key];
          }
        }
      });
      console.log(this.messageCountXH + this.messageCountHT);
      this.localStorage.setObject(
        "functionNum",
        this.messageCountXH + this.messageCountHT
      );
    } catch (e) {}
  }

  getIcon(id: string): string {
    const iconName = this[id];
    return iconName;
  }
}
