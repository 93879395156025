<app-bottom-nav></app-bottom-nav>
<div class="dash-container animated fadeInUp">
  <mat-tab-group
    (selectedTabChange)="changeTab($event)"
    [selectedIndex]="dbCode"
  >
    <mat-tab label="XH">
      <ng-template mat-tab-label>
        XH
        <mat-badge
          class="firstBadgeTop"
          [matBadge]="messageCountXH > 99 ? '99+' : messageCountXH"
          matBadgeColor="warn"
          [matBadgeHidden]="messageCountXH == 0"
        ></mat-badge>
      </ng-template>
      <div class="dash-row">
        <button
          class="dash-item inshowList"
          mat-button
          routerLink="{{ item.url }}"
          [queryParams]="item.queryParams"
          *ngFor="let item of showList"
        >
          <fa-icon [icon]="getIcon(item.icon)"></fa-icon>
          <div>{{ item.title }}</div>
          <div *ngFor="let data of matBadgeXH">
            <mat-badge
              *ngIf="item.title == data.title"
              class="firstBadge"
              [matBadge]="data.msgCount > 99 ? '99+' : data.msgCount"
              matBadgeColor="warn"
              [matBadgeHidden]="data.msgCount == 0"
            ></mat-badge>
          </div>
        </button>
      </div>
    </mat-tab>

    <mat-tab label="HT">
      <ng-template mat-tab-label>
        HT
        <mat-badge
          class="firstBadgeTop"
          [matBadge]="messageCountHT > 99 ? '99+' : messageCountHT"
          matBadgeColor="warn"
          [matBadgeHidden]="messageCountHT == 0"
        ></mat-badge>
      </ng-template>
      <div class="dash-row">
        <button
          class="dash-item inshowList"
          mat-button
          routerLink="{{ item.url }}"
          [queryParams]="item.queryParams"
          *ngFor="let item of showListHT"
        >
          <fa-icon [icon]="getIcon(item.icon)"></fa-icon>
          <div>{{ item.title }}</div>
          <div *ngFor="let data of matBadgeHT">
            <mat-badge
              *ngIf="item.title == data.title"
              class="firstBadge"
              [matBadge]="data.msgCount > 99 ? '99+' : data.msgCount"
              matBadgeColor="warn"
              [matBadgeHidden]="data.msgCount == 0"
            ></mat-badge>
          </div>
        </button>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
