import { Component, OnInit, Input } from "@angular/core";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Location } from "@angular/common";
import { Router } from "@angular/router";

@Component({
  selector: "app-topnav",
  templateUrl: "./topnav.component.html",
  styleUrls: ["./topnav.component.scss"],
})
export class TopnavComponent implements OnInit {
  faArrowLeft = faAngleLeft;
  @Input() titleText: string;
  @Input() topNavImgSrc: string;
  @Input() callbackUrl: string;

  constructor(private loc: Location, private router: Router) {}

  ngOnInit() {}

  backCmd() {
    let url = "/core/dashboard";
    let parameter = {}
    if (this.callbackUrl) {
      const splitList = this.callbackUrl.split('?');
      url = splitList[0];
      if(splitList[1]){
        const paramList = splitList[1].split('&');
        for(let param of paramList) {
          const keyValueList = param.split('=');
          parameter[keyValueList[0]] = JSON.parse(keyValueList[1].replace(/'/g, '"'));
        }
      }
    }
    
    // this.loc.back();
    this.router.navigate([url, parameter]);
  }
}
