import { Injectable } from '@angular/core';
import { catchError, delay, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {LocalStorage} from '../utils/dataStore';

/**
 * 用户验证服务，实现处理登入登出方法
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = sessionStorage.getItem('token') || false;
  redirectUrl: string;
  user: any;
  id;
  roleIdList;
  private localStorage = new  LocalStorage;

  /**
   * 登入服务
   * @param custCode 使用者公司编码
   * @param userName 使用者账号
   * @param password 密码
   */
  public login(custCode: string, userName: string, password: string) {
    const header: HttpHeaders = new HttpHeaders();
    header.append('Content-Type', 'x-access-token');

    return this.http
      .post<any>(
        sessionStorage.getItem('baseUrl') + '/users/login',
        { staffcode: userName, password: password, custCode: custCode },
        { headers: header }
      )
      .pipe(
        map((resp) => {
          if (resp.token) {
            sessionStorage.setItem('token', resp.token);
            //sessionStorage.setItem('db-code', 'XH');//登录默认显示新豪
            sessionStorage.setItem('user', JSON.stringify(resp.data.user));
            sessionStorage.setItem('roleId', JSON.stringify(resp.data.roleIdList));
            sessionStorage.setItem('menuIconList', JSON.stringify(resp.data.menuIconList));
            const menuObj = {};
            if (resp.data.menuList && resp.data.menuList.length) {
              const menuList: any = this.getTree(0, resp.data.menuList, []);
              for (const  menu of menuList) {
                menuObj[menu.path] = {};
                if (menu.children) {
                  for (const item of  menu.children) {
                    menuObj[menu.path][item.path] = true;
                  }
                }
              }
            }
            this.localStorage.setObject('menu', menuObj);
            this.isLoggedIn = true;
            this.user = resp.data.user;
            this.roleIdList = resp.data.roleIdList;
            return true;
          } else {
            this.isLoggedIn = false;
            throw new Error(
              '服务器令牌格式有误，请联系系统管理员(token字段不存在)。'
            );
          }
        })
      );
  }

  /*  private handleError<T> (operation = 'operation', result?: T) {
    return(error: any): Observable<T> => {
      console.error(error);
      // this.log(`${operation} failed:${error.message}`);
      return of(result as T);
    };
  }*/

  // 拼接树结构（目前两层）
  getTree(parentId, arr, res) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].parentId === parentId) {
        arr[i].fold = false;
        res.push(arr[i]);
        arr.splice(i, 1);
        i--;
      }
    }
    res.map(r => {
      r.children = [];
      this.getTree(r.id, arr, r.children);
      if (r.children.length === 0) {
        delete r.children;
      }
    });
    return res;
  }

  public nfcLogin(tagText: string) {
    const header: HttpHeaders = new HttpHeaders();
    header.append('Content-Type', 'x-access-token');
    return this.http
      .post<any>(
        sessionStorage.getItem('baseUrl') + '/users/loginTag',
        { tagCode: tagText },
        { headers: header }
      )
      .pipe(
        map((resp) => {
          if (resp.token) {
            sessionStorage.setItem('token', resp.token);
            //sessionStorage.setItem('db-code', 'XH');//登录默认显示新豪
            sessionStorage.setItem('user', JSON.stringify(resp.data.user));
            sessionStorage.setItem('roleId', JSON.stringify(resp.data.roleIdList));
            sessionStorage.setItem('menuIconList', JSON.stringify(resp.data.menuIconList));
            // this.isLoggedIn = true;
            this.user = resp.data.user;
            this.roleIdList = resp.data.roleIdList;

            return true;
          } else {
            throw new Error(
              '服务器令牌格式有误，请联系系统管理员(token字段不存在)。'
            );
          }
        })
      );
  }

  public nfcGetUser(tagText: string): any {
    const header: HttpHeaders = new HttpHeaders();
    header.append('Content-Type', 'x-access-token');
    return this.http
      .post<any>(
        sessionStorage.getItem('baseUrl') + '/users/loginTag',
        { tagCode: tagText },
        { headers: header }
      )
      .pipe(
        map((resp) => {
          if (resp.token) {
            return resp.data.user;
          } else {
            throw new Error(
              '服务器令牌格式有误，请联系系统管理员(token字段不存在)。'
            );
          }
        })
      );
  }

  /**
   * 登出服务
   */
  logout(): void {
    this.isLoggedIn = false;
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
  }

  constructor(private http: HttpClient) {}
}
