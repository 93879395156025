/**
 * IPE Group Limited http://www.ipegroup.com
 * Copyright 2019
 * Created by wah on 3/16/2020.
 */
import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {

      // 检查是否要忽略该请求的请求头
      if (req.url.includes('/k3c/getMsgList') || req.url.includes('/msg/UserUntreatedCount')) {
        return next.handle(req); // 不修改请求头，直接发送请求
      }
  
      // 对其他请求添加自定义请求头
    const modifiedReq = req.clone({
      setHeaders: {Authorization: 'Bearer ' + sessionStorage.getItem('token'),'db-code':sessionStorage.getItem('db-code') || 'XH'},
    });
    return next.handle(modifiedReq);
  }
}
