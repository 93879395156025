<h2>调整数量</h2>
<mat-dialog-content [formGroup]="form">

  <mat-form-field>
    <input matInput
           placeholder="支数"
           formControlName="qty_sec"
           (click)="$event.target.select()">
  </mat-form-field>
  <mat-form-field>
    <input matInput
           placeholder="公斤"
           formControlName="qty"
           (click)="$event.target.select()">
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="mat-button mat-primary" (click)="close()">取消</button>
  <button class="mat-raised-button mat-primary"
          (click)="save()">保存</button>
</mat-dialog-actions>
