export const Menulist = {
  //原材料-叉车司机
  1: [],
  //原材料-车间收料
  2: [
    {
      title: "原材料签收确认",
      icon: "faCheckSquare",
      url: "../production/prodReceConfirmDetail",
    },
  ],
  //辅料-车间收料
  23: [
    {
      title: "辅料签收确认",
      icon: "faCheckSquare",
      url: "../production/prodReceConfirmDetail",
      queryParams: {
        type: 16,
      },
    },
  ],
  //散件-车间收料
  22: [
    {
      title: "散件签收确认",
      icon: "faCheckSquare",
      url: "../production/prodReceConfirmDetail",
      queryParams: {
        type: 21,
      },
    },
    {
      title: "补打贴纸",
      icon: "faStickyNote",
      url: "../warehouse/touchupSticker",
    },
    {
      title: "标签禁用",
      icon: "faSearch",
      url: "../warehouse/ticketDeactivate",
    },
  ],
  // 成品-入库签收员
  30: [
    {
      title: "成品入库待签收",
      icon: "faUserCheck",
      url: "../production/prodFinStockIn",
    },
    {
      title: "货位转移",
      icon: "faSync",
      url: "../msg/location-transfer",
    },
  ],
  // 原材料-备料员
  3: [
    {
      title: "货位转移",
      icon: "faSync",
      url: "../msg/location-transfer",
    },
    {
      title: "生产领料",
      icon: "faCity",
      url: "../production/prodDispatch",
    },
    {
      title: "原材料签收确认",
      icon: "faCheckSquare",
      url: "../production/prodReceConfirmDetail",
    },
    {
      title: "仓库转移",
      icon: "faTruckLoading",
      url: "../msg/warehouse-transfer",
    },
    {
      title: "标签查询",
      icon: "faSearch",
      url: "../warehouse/ticketDetailView",
    },
    {
      title: "金蝶查询",
      icon: "faSearch",
      url: "../warehouse/kingdeeSearch",
    },
    {
      title: "标签激活",
      icon: "faSearch",
      url: "../warehouse/ticketReactivate",
    },
    {
      title: "生产退料",
      icon: "faCity",
      url: "../production/prodReturn",
    },
    {
      title: "盘点现况",
      icon: "faCity",
      url: "../stocktake/resultRealtime",
    },
    {
      title: "盘点控制台",
      icon: "faCity",
      url: "../stocktake/stocktakeActivities",
    },
  ],
  // 辅料-备料员
  18: [
    {
      title: "货位转移",
      icon: "faSync",
      url: "../msg/location-transfer",
    },
    {
      title: "出库申请单",
      icon: "faStickyNote",
      url: "../production/miscStockOutApply",
    },
    {
      title: "辅料签收确认",
      icon: "faCheckSquare",
      url: "../production/prodReceConfirmDetail",
      queryParams: {
        type: 16,
      },
    },
    {
      title: "标签查询",
      icon: "faSearch",
      url: "../warehouse/ticketDetailView",
    },
    {
      title: "金蝶查询",
      icon: "faSearch",
      url: "../warehouse/kingdeeSearch",
    },
    {
      title: "标签激活",
      icon: "faSearch",
      url: "../warehouse/ticketReactivate",
    },
    {
      title: "仓库转移",
      icon: "faTruckLoading",
      url: "../msg/warehouse-transfer",
    },
    {
      title: "生产退料",
      icon: "faCity",
      url: "../production/prodReturn",
    },
    {
      title: "盘点现况",
      icon: "faCity",
      url: "../stocktake/resultRealtime",
    },
    {
      title: "盘点控制台",
      icon: "faCity",
      url: "../stocktake/stocktakeActivities",
    },
  ],
  // 散件-备料员
  24: [
    {
      title: "货位转移",
      icon: "faSync",
      url: "../msg/location-transfer",
    },
    {
      title: "半成品出库",
      icon: "faCoffee",
      url: "../warehouse/MaterialStockOut",
      queryParams: {
        type: "semi",
      },
    },
    {
      title: "散件签收确认",
      icon: "faCheckSquare",
      url: "../production/prodReceConfirmDetail",
      queryParams: {
        type: 21,
      },
    },
    {
      title: "标签查询",
      icon: "faSearch",
      url: "../warehouse/ticketDetailView",
    },
    {
      title: "金蝶查询",
      icon: "faSearch",
      url: "../warehouse/kingdeeSearch",
    },
    {
      title: "标签激活",
      icon: "faSearch",
      url: "../warehouse/ticketReactivate",
    },
    {
      title: "仓库转移",
      icon: "faTruckLoading",
      url: "../msg/warehouse-transfer",
    },
    {
      title: "生产退料",
      icon: "faCity",
      url: "../production/prodReturn",
    },
    {
      title: "盘点现况",
      icon: "faCity",
      url: "../stocktake/resultRealtime",
    },
    {
      title: "盘点控制台",
      icon: "faCity",
      url: "../stocktake/stocktakeActivities",
    },
    {
      title: "补打贴纸",
      icon: "faStickyNote",
      url: "../warehouse/touchupSticker",
    },
    {
      title: "标签禁用",
      icon: "faSearch",
      url: "../warehouse/ticketDeactivate",
    },
  ],
  // 原材料-搬运工
  4: [
    {
      title: "原材料货位转移",
      icon: "faSync",
      url: "../msg/carry-task",
    },
    {
      title: "原材料运输",
      icon: "faTruck",
      url: "../msg/invinfo-task",
      queryParams: {
        type: 1,
      },
    },
    {
      title: "原材料搬运-退货",
      icon: "faSync",
      url: "../msg/task-detail",
      queryParams: {
        type: 13,
      },
    },
    {
      title: "标签查询",
      icon: "faSearch",
      url: "../warehouse/ticketDetailView",
    },
    {
      title: "标签激活",
      icon: "faSearch",
      url: "../warehouse/ticketReactivate",
    },
    {
      title: "标签禁用",
      icon: "faSearch",
      url: "../warehouse/ticketDeactivate",
    },
    {
      title: "金蝶查询",
      icon: "faSearch",
      url: "../warehouse/kingdeeSearch",
    },
    {
      title: "货位转移",
      icon: "faSync",
      url: "../msg/location-transfer",
    },
    {
      title: "仓库转移",
      icon: "faTruckLoading",
      url: "../msg/warehouse-transfer",
    },
    {
      title: "生产领料",
      icon: "faCity",
      url: "../production/prodDispatch",
    },
    {
      title: "生产退料",
      icon: "faCity",
      url: "../production/prodReturn",
    },
    {
      title: "原材料签收确认",
      icon: "faCheckSquare",
      url: "../production/prodReceConfirmDetail",
    },
    {
      title: "盘点现况",
      icon: "faCity",
      url: "../stocktake/resultRealtime",
    },
    {
      title: "盘点控制台",
      icon: "faCity",
      url: "../stocktake/stocktakeActivities",
    },
    {
      title: "补打贴纸",
      icon: "faStickyNote",
      url: "../warehouse/touchupSticker",
    },
  ],
  // 原材料-IQC
  6: [
    {
      title: "IQC",
      icon: "faStickyNote",
      url: "../warehouse/ticketIQC",
      queryParams: {
        IQCType: 0,
      },
    },
    {
      title: "补打贴纸",
      icon: "faStickyNote",
      url: "../warehouse/touchupSticker",
    },
    {
      title: "标签禁用",
      icon: "faSearch",
      url: "../warehouse/ticketDeactivate",
    },
  ],
  // 散件-IQC
  20: [
    {
      title: "散件-IQC",
      icon: "faStickyNote",
      url: "../warehouse/ticketIQC",
      queryParams: {
        IQCType: 2,
      },
    },
    {
      title: "补打贴纸",
      icon: "faStickyNote",
      url: "../warehouse/touchupSticker",
    },
    {
      title: "标签禁用",
      icon: "faSearch",
      url: "../warehouse/ticketDeactivate",
    },
  ],
  // 量具-IQC
  26: [
    {
      title: "量具-IQC",
      icon: "faStickyNote",
      url: "../warehouse/ticketIqcStateLg",
      queryParams: {
        IQCType: 1,
      },
    },
    {
      title: "查询货物库存",
      icon: "faSearch",
      url: "../warehouse/ticket-query",
    },
  ],
  // 辅料-搬运工
  9: [
    {
      title: "标签查询",
      icon: "faSearch",
      url: "../warehouse/ticketDetailView",
    },
    {
      title: "标签激活",
      icon: "faSearch",
      url: "../warehouse/ticketReactivate",
    },
    {
      title: "金蝶查询",
      icon: "faSearch",
      url: "../warehouse/kingdeeSearch",
    },
    {
      title: "货位转移",
      icon: "faSync",
      url: "../msg/location-transfer",
    },
    {
      title: "仓库转移",
      icon: "faTruckLoading",
      url: "../msg/warehouse-transfer",
    },
    {
      title: "生产领料",
      icon: "faCity",
      url: "../production/prodDispatch",
    },
    {
      title: "生产退料",
      icon: "faCity",
      url: "../production/prodReturn",
    },
    {
      title: "辅料签收确认",
      icon: "faCheckSquare",
      url: "../production/prodReceConfirmDetail",
      queryParams: {
        type: 16,
      },
    },
    {
      title: "盘点现况",
      icon: "faCity",
      url: "../stocktake/resultRealtime",
    },
    {
      title: "盘点控制台",
      icon: "faCity",
      url: "../stocktake/stocktakeActivities",
    },
    {
      title: "补打贴纸",
      icon: "faStickyNote",
      url: "../warehouse/touchupSticker",
    },
    {
      title: "标签禁用",
      icon: "faSearch",
      url: "../warehouse/ticketDeactivate",
    },
  ],
  // 散件-搬运工
  19: [
    {
      title: "标签查询",
      icon: "faSearch",
      url: "../warehouse/ticketDetailView",
    },
    {
      title: "标签激活",
      icon: "faSearch",
      url: "../warehouse/ticketReactivate",
    },
    {
      title: "金蝶查询",
      icon: "faSearch",
      url: "../warehouse/kingdeeSearch",
    },
    {
      title: "货位转移",
      icon: "faSync",
      url: "../msg/location-transfer",
    },
    {
      title: "仓库转移",
      icon: "faTruckLoading",
      url: "../msg/warehouse-transfer",
    },
    {
      title: "生产领料",
      icon: "faCity",
      url: "../production/prodDispatch",
    },
    {
      title: "生产退料",
      icon: "faCity",
      url: "../production/prodReturn",
    },
    {
      title: "散件签收确认",
      icon: "faCheckSquare",
      url: "../production/prodReceConfirmDetail",
      queryParams: {
        type: 21,
      },
    },
    {
      title: "盘点现况",
      icon: "faCity",
      url: "../stocktake/resultRealtime",
    },
    {
      title: "盘点控制台",
      icon: "faCity",
      url: "../stocktake/stocktakeActivities",
    },
    {
      title: "补打贴纸",
      icon: "faStickyNote",
      url: "../warehouse/touchupSticker",
    },
    {
      title: "标签禁用",
      icon: "faSearch",
      url: "../warehouse/ticketDeactivate",
    },
  ],
  // 成品-入库搬运工
  29: [
    {
      title: "成品入库搬运",
      icon: "faLuggageCart",
      url: "../production/prodFinCarry",
    },
  ],
  // 原材料管理组
  10: [
    {
      title: "物料买卖",
      icon: "faBox",
      url: "../sales/matSaleList",
      queryParams: {
        type: 0,
        pageType: 0,
      },
    },
    {
      title: "采购退料",
      icon: "faRedo",
      url: "../sales/matSaleList",
      queryParams: {
        type: 1,
        pageType: 0,
      },
    },
    {
      title: "外发入库",
      icon: "faCube",
      url: "../sales/outsourceStockInList",
      queryParams: {
        type: 1,
      },
    },
    {
      title: "外发出库",
      icon: "faCube",
      url: "../sales/outsourceStockOut",
      queryParams: {
        type: 1,
      },
    },
    {
      title: "返厂入库",
      icon: "faCube",
      url: "../sales/outsourceStockOut",
      queryParams: {
        type: 2,
      },
    },
    {
      title: "返厂签收",
      icon: "faCube",
      url: "../sales/outsourceStockInList",
      queryParams: {
        type: 2,
      },
    },
    {
      title: "外发记录查询",
      icon: "faDesktop",
      url: "../sales/outsourceQuery",
    },
    {
      title: "查询货物库存",
      icon: "faSearch",
      url: "../warehouse/ticket-query",
    },
    {
      title: "货位转移",
      icon: "faSync",
      url: "../msg/location-transfer",
    },
    {
      title: "收料通知",
      icon: "faTruckLoading",
      url: "../purchasing/purArrivalNotice",
    },
    {
      title: "EOL调拨",
      icon: "faWaveSquare",
      url: "../purchasing/purAllocateList",
      queryParams: {
        type: 0,
        pageType: 1,
      },
    },
    {
      title: "嗦扶咀回收",
      icon: "faTools",
      url: "../purchasing/purAllocateList",
      queryParams: {
        type: 1,
        pageType: 0,
      },
    },
    {
      title: "跨组织调拨",
      icon: "faWaveSquare",
      url: "../purchasing/purAllocateList",
      queryParams: {
        type: 0,
        pageType: 1,
      },
    },
    {
      title: "采购订单入库",
      icon: "faInbox",
      url: "../warehouse/MaterialStockIn",
    },
    {
      title: "补打贴纸",
      icon: "faStickyNote",
      url: "../warehouse/touchupSticker",
    },
    {
      title: "标签禁用",
      icon: "faSearch",
      url: "../warehouse/ticketDeactivate",
    },
    {
      title: "采购入库",
      icon: "faSearchPlus",
      url: "../warehouse/PurchaseStockIn",
    },
    {
      title: "采购入库-打印卡片",
      icon: "faCity",
      url: "../warehouse/ticketPrint",
    },
    {
      title: "IQC",
      icon: "faStickyNote",
      url: "../warehouse/ticketIQC",
      queryParams: {
        IQCType: 0,
      },
    },
    {
      title: "原材料货位转移",
      icon: "faSync",
      url: "../msg/carry-task",
    },
    {
      title: "生产领料",
      icon: "faCity",
      url: "../production/prodDispatch",
    },
    {
      title: "成品出库",
      icon: "faDoorOpen",
      url: "../production/prodFinished",
    },
    {
      title: "原材料运输",
      icon: "faTruck",
      url: "../msg/invinfo-task",
      queryParams: {
        type: 1,
      },
    },
    {
      title: "成品运输任务",
      icon: "faTruck",
      url: "../warehouse/MaterialTransportTask",
      queryParams: {
        shipstatus: 0,
        type: 39,
      },
    },
    {
      title: "原材料签收确认",
      icon: "faCheckSquare",
      url: "../production/prodReceConfirmDetail",
    },
    {
      title: "成品签收确认",
      icon: "faCheckSquare",
      url: "../production/ProdReceConfirmDetail",
      queryParams: {
        type: 40,
      },
    },
    {
      title: "成品签收确认",
      icon: "faCheckSquare",
      url: "../production/prodReceConfirmDetail",
      queryParams: {
        type: 40,
      },
    },
    {
      title: "标签查询",
      icon: "faSearch",
      url: "../warehouse/ticketDetailView",
    },
    {
      title: "金蝶查询",
      icon: "faSearch",
      url: "../warehouse/kingdeeSearch",
    },
    {
      title: "收发明细查询",
      icon: "faSearch",
      url: "../warehouse/DetailsQuery",
    },
    {
      title: "标签激活",
      icon: "faSearch",
      url: "../warehouse/ticketReactivate",
    },
    {
      title: "仓库转移",
      icon: "faTruckLoading",
      url: "../msg/warehouse-transfer",
    },
    {
      title: "保税信息",
      icon: "faFile",
      url: "../setting/versionSetting",
    },
    {
      title: "汇总码打印",
      icon: "faPrint",
      url: "../warehouse/SummaryCodePrint",
    },
    {
      title: "超时未签收明细",
      icon: "faClock",
      url: "../warehouse/TimeoutSign",
    },
    {
      title: "物料退仓",
      icon: "faRetweet",
      url: "../warehouse/materialReturn",
    },
    {
      title: "采购收货",
      icon: "faTruckLoading",
      url: "../purchasing/purStockIn",
    },
    {
      title: "采购退货",
      icon: "faLuggageCart",
      url: "../purchasing/purReturn",
    },
    {
      title: "TMS",
      icon: "faBoxOpen",
      url: "../warehouse/otherStockIn",
    },
    {
      title: "其他出库",
      icon: "faBox",
      url: "../warehouse/otherStockOut",
    },
    {
      title: "生产退料",
      icon: "faCity",
      url: "../production/prodReturn",
    },
    {
      title: "生产完工",
      icon: "faCity",
      url: "../production/prodCompleted",
    },
    {
      title: "成品退回",
      icon: "faExchangeAlt",
      url: "../production/prodCompletedReturn",
    },
    {
      title: "标签维护",
      icon: "faBox",
      url: "../warehouse/otherStockOut",
    },
    {
      title: "销售发货",
      icon: "faTruckLoading",
      url: "../sales/salesDispatch",
    },
    {
      title: "销售退货",
      icon: "faExchangeAlt",
      url: "../sales/salesReturn",
    },
    {
      title: "盘点现况",
      icon: "faCity",
      url: "../stocktake/resultRealtime",
    },
  ],
  // 辅料管理组
  11: [
    {
      title: "查询货物库存",
      icon: "faSearch",
      url: "../warehouse/ticket-query",
    },
    {
      title: "货位转移",
      icon: "faSync",
      url: "../msg/location-transfer",
    },
    {
      title: "收料通知",
      icon: "faTruckLoading",
      url: "../purchasing/purArrivalNotice",
    },
    {
      title: "采购订单入库",
      icon: "faInbox",
      url: "../warehouse/MaterialStockIn",
      // queryParams: {
      //   type: 'master',
      // }
    },
    {
      title: "补打贴纸",
      icon: "faStickyNote",
      url: "../warehouse/touchupSticker",
    },
    {
      title: "标签禁用",
      icon: "faSearch",
      url: "../warehouse/ticketDeactivate",
    },
    {
      title: "采购入库",
      icon: "faSearchPlus",
      url: "../warehouse/PurchaseStockIn",
    },
    {
      title: "采购入库-打印卡片",
      icon: "faCity",
      url: "../warehouse/ticketPrint",
    },
    {
      title: "生产领料",
      icon: "faCity",
      url: "../production/prodDispatch",
    },
    {
      title: "出库申请单",
      icon: "faStickyNote",
      url: "../production/miscStockOutApply",
    },
    {
      title: "辅料运输任务",
      icon: "faTruck",
      url: "../warehouse/MaterialTransportTask",
      queryParams: {
        shipstatus: 0,
        type: 15,
      },
    },
    {
      title: "辅料签收确认",
      icon: "faCheckSquare",
      url: "../production/prodReceConfirmDetail",
      queryParams: {
        type: 16,
      },
    },
    {
      title: "标签查询",
      icon: "faSearch",
      url: "../warehouse/ticketDetailView",
    },
    {
      title: "金蝶查询",
      icon: "faSearch",
      url: "../warehouse/kingdeeSearch",
    },
    {
      title: "收发明细查询",
      icon: "faSearch",
      url: "../warehouse/DetailsQuery",
    },
    {
      title: "标签激活",
      icon: "faSearch",
      url: "../warehouse/ticketReactivate",
    },
    {
      title: "仓库转移",
      icon: "faTruckLoading",
      url: "../msg/warehouse-transfer",
    },
    {
      title: "保税信息",
      icon: "faFile",
      url: "../setting/versionSetting",
    },
    {
      title: "汇总码打印",
      icon: "faPrint",
      url: "../warehouse/SummaryCodePrint",
    },
    {
      title: "超时未签收明细",
      icon: "faClock",
      url: "../warehouse/TimeoutSign",
    },
    {
      title: "采购收货",
      icon: "faTruckLoading",
      url: "../purchasing/purStockIn",
    },
    {
      title: "采购退货",
      icon: "faLuggageCart",
      url: "../purchasing/purReturn",
    },

    {
      title: "TMS",
      icon: "faBoxOpen",
      url: "../warehouse/otherStockIn",
    },
    {
      title: "其他出库",
      icon: "faBox",
      url: "../warehouse/otherStockOut",
    },
    {
      title: "标签维护",
      icon: "faBox",
      url: "../warehouse/otherStockOut",
    },
    {
      title: "生产退料",
      icon: "faCity",
      url: "../production/prodReturn",
    },
    {
      title: "生产完工",
      icon: "faCity",
      url: "../production/prodCompleted",
    },
    {
      title: "成品退回",
      icon: "faExchangeAlt",
      url: "../production/prodCompletedReturn",
    },
    {
      title: "销售发货",
      icon: "faTruckLoading",
      url: "../sales/salesDispatch",
    },
    {
      title: "销售退货",
      icon: "faExchangeAlt",
      url: "../sales/salesReturn",
    },
    {
      title: "盘点现况",
      icon: "faCity",
      url: "../stocktake/resultRealtime",
    },
    {
      title: "量具-IQC",
      icon: "faStickyNote",
      url: "../warehouse/ticketIqcStateLg",
      queryParams: {
        IQCType: 1,
      },
    },
  ],
  // 散件管理组
  12: [
    {
      title: "查询货物库存",
      icon: "faSearch",
      url: "../warehouse/ticket-query",
    },
    {
      title: "货位转移",
      icon: "faSync",
      url: "../msg/location-transfer",
    },
    {
      title: "收料通知",
      icon: "faTruckLoading",
      url: "../purchasing/purArrivalNotice",
    },
    {
      title: "采购订单入库",
      icon: "faInbox",
      url: "../warehouse/MaterialStockIn",
      // queryParams: {
      //   type: 'master',
      // }
    },
    {
      title: "采购入库",
      icon: "faSearchPlus",
      url: "../warehouse/PurchaseStockIn",
    },
    {
      title: "采购入库-打印卡片",
      icon: "faCity",
      url: "../warehouse/ticketPrint",
    },
    {
      title: "补打贴纸",
      icon: "faStickyNote",
      url: "../warehouse/touchupSticker",
    },
    {
      title: "标签禁用",
      icon: "faSearch",
      url: "../warehouse/ticketDeactivate",
    },
    {
      title: "散件-IQC",
      icon: "faStickyNote",
      url: "../warehouse/ticketIQC",
      queryParams: {
        IQCType: 2,
      },
    },
    {
      title: "生产领料",
      icon: "faCity",
      url: "../production/prodDispatch",
    },
    {
      title: "半成品出库",
      icon: "faCoffee",
      url: "../warehouse/MaterialStockOut",
      queryParams: {
        type: "semi",
      },
    },
    {
      title: "散件签收确认",
      icon: "faCheckSquare",
      url: "../production/prodReceConfirmDetail",
      queryParams: {
        type: 21,
      },
    },
    {
      title: "标签查询",
      icon: "faSearch",
      url: "../warehouse/ticketDetailView",
    },
    {
      title: "金蝶查询",
      icon: "faSearch",
      url: "../warehouse/kingdeeSearch",
    },
    {
      title: "收发明细查询",
      icon: "faSearch",
      url: "../warehouse/DetailsQuery",
    },
    {
      title: "收发明细查询",
      icon: "faSearch",
      url: "../warehouse/DetailsQuery",
    },
    {
      title: "标签激活",
      icon: "faSearch",
      url: "../warehouse/ticketReactivate",
    },
    {
      title: "仓库转移",
      icon: "faTruckLoading",
      url: "../msg/warehouse-transfer",
    },
    {
      title: "保税信息",
      icon: "faFile",
      url: "../setting/versionSetting",
    },
    {
      title: "汇总码打印",
      icon: "faPrint",
      url: "../warehouse/SummaryCodePrint",
    },
    {
      title: "超时未签收明细",
      icon: "faClock",
      url: "../warehouse/TimeoutSign",
    },
    {
      title: "采购收货",
      icon: "faTruckLoading",
      url: "../purchasing/purStockIn",
    },
    {
      title: "采购退货",
      icon: "faLuggageCart",
      url: "../purchasing/purReturn",
    },
    {
      title: "TMS",
      icon: "faBoxOpen",
      url: "../warehouse/otherStockIn",
    },
    {
      title: "其他出库",
      icon: "faBox",
      url: "../warehouse/otherStockOut",
    },
    {
      title: "生产退料",
      icon: "faCity",
      url: "../production/prodReturn",
    },
    {
      title: "生产完工",
      icon: "faCity",
      url: "../production/prodCompleted",
    },
    {
      title: "标签维护",
      icon: "faBox",
      url: "../warehouse/otherStockOut",
    },
    {
      title: "成品退回",
      icon: "faExchangeAlt",
      url: "../production/prodCompletedReturn",
    },

    {
      title: "销售发货",
      icon: "faTruckLoading",
      url: "../sales/salesDispatch",
    },
    {
      title: "销售退货",
      icon: "faExchangeAlt",
      url: "../sales/salesReturn",
    },
    {
      title: "盘点现况",
      icon: "faCity",
      url: "../stocktake/resultRealtime",
    },
  ],
};

export default Menulist;
