/**
 * Created by Cihong.liu
 * Material Module
 */
import { NgModule } from "@angular/core";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatPaginatorModule } from "@angular/material/paginator";

@NgModule({
    exports:
        [
            MatAutocompleteModule,
            MatSidenavModule,
            MatButtonModule,
            MatToolbarModule,
            MatMenuModule,
            MatFormFieldModule,
            MatInputModule,
            MatCardModule,
            MatDatepickerModule,
            MatCheckboxModule,
            MatSelectModule,
            MatTableModule,
            MatDividerModule,
            MatSnackBarModule,
            MatDialogModule,
            MatMomentDateModule,
            MatProgressSpinnerModule,
            MatListModule,
            MatPaginatorModule
        ]
})
export class MaterialModule { }