import { MediaMatcher } from "@angular/cdk/layout";
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  SimpleChange,
  ViewEncapsulation,
} from "@angular/core";
import {
  faBars,
  faHome,
  faCog,
  faUser,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { LocalStorage } from "../../../utils/dataStore";

@Component({
  selector: "app-bottom-nav",
  templateUrl: "./bottom-nav.component.html",
  styleUrls: ["./bottom-nav.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BottomNavComponent implements OnInit, OnDestroy {
  // mobileQuery: MediaQueryList;
  // _mobileQueryListener: MediaQueryListListener; // outdated
  fillerNav: object;
  faBars = faBars;
  faHome = faHome;
  faCog = faCog;
  faUser = faUser;
  faComment = faComment;
  messageCount = 0;
  functionNum = 0;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private localStorage: LocalStorage
  ) {
    // this.mobileQuery = media.matchMedia('(max-width: 400px)');
    // this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.getCount();
  }

  ngDoCheck(): void {
    this.getCount();
  }

  getCount() {
    this.messageCount =
      (parseInt(this.localStorage.getObject("messageCountXH")) +
      parseInt(this.localStorage.getObject("messageCountHT"))) || 0;
    this.functionNum = parseInt(this.localStorage.getObject("functionNum")) || 0;
  }

  ngOnDestroy() {
    // this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
