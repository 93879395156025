import { Component, Inject, OnDestroy, OnInit, NgZone, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PurArriveNoticeDetailComponent } from '../../purchasing/pur-arrive-notice-detail/pur-arrive-notice-detail.component';
import { TaskDetailComponent } from '../../chat/task-detail/task-detail.component';
@Component({
  selector: 'app-toast-dialog',
  templateUrl: './toast-dialog.component.html',
  styleUrls: ['./toast-dialog.component.scss']
})
export class ToastDialogComponent implements OnInit {
  data: ''
  constructor(
    private zone: NgZone,

    private dialogRef: MatDialogRef<
      PurArriveNoticeDetailComponent,
      TaskDetailComponent
    >,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.close()
    }, 2000)
  }



  close() {
    this.dialogRef.close();
  }


  ngOnDestroy(): void {

  }
}
