import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusyService {

  private isBusySubject = new BehaviorSubject(false);
  public isBusy = this.isBusySubject.asObservable();

  constructor() {
  }

  public setLoading (isLoading: boolean) {
    this.isBusySubject.next(isLoading);
  }
}
