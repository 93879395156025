import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import _ from 'lodash';

import { ConfigService } from '../utilities/config.service';
import { CurrentDbCode } from './current-db-code';
import { SnackBarService } from '../utilities';

@Injectable({
    providedIn: 'root'
})
export class StkOrganizationService {

    private stkOrganizationCache: IStkOrganization[] = [];

    constructor(
        private http: HttpClient,
        private snackbar: SnackBarService,
        private configService: ConfigService
    ) { }

    /**
     * 获取数据组织信息
     * @param dbCode 数据中心 XH：新豪 HT：汇通
     * @returns 
     */
    getOrganizations(dbCode?: 'XH' | 'HT') {
        if (this.stkOrganizationCache.length) {
            return of((_.groupBy(this.stkOrganizationCache, v => v.dbCode))[dbCode || this.getCurrentDbCode()]);
        }
        return this.http.get<{ status: 'success' | 'fail', message: string, results: number, data: IStkOrganization[] }>(`${this.configService.baseUrl}/stkOrganization`)
            .pipe(
                map((result => {
                    if (result.status === 'success') {
                        this.stkOrganizationCache = [...result.data];
                        return (_.groupBy(this.stkOrganizationCache, v => v.dbCode))[dbCode || this.getCurrentDbCode()];
                    }
                    //this.snackbar.open(result.message, '关闭', { duration: 5000 });
                    this.snackbar.sticky(result.message);
                    return [];
                })),
                // 异常信息
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    const { message } = httpErrorResponse.error || {};
                    this.snackbar.sticky(message || httpErrorResponse.message)
                    return EMPTY;
                })
            );
    }

    getCurrentDbCode() {
        return CurrentDbCode();
    }
}

export interface IStkOrganization {
    /** 主键 id */
    id: number;
    /** 组织代码 */
    stkOrganizationCode: string;
    /** 组强名称 */
    stkOrganizationName: string;
    /** 数据中心 XH：新豪 HT：汇通 */
    dbCode: 'XH' | 'HT';
}
