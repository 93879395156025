<app-bottom-nav></app-bottom-nav>
<div class="user-info-container animated fadeInUp">
  <div class="section">
    <div class="section-line main">
      <img class="section-line-item" src="/assets/img/avatar.jpg"/>
      <div class="section-line-item user-info-text">
        <div class="username">{{currentUser['name']}}</div>
        <div class="user-info">国际精密 - {{currentUser['dept']}}</div>
        <div class="user-info">账号：{{currentUser['staffcode']}}</div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="section-line sub">
      <div class="section-line-item"><fa-icon class="info-icon" [icon]="faCog"></fa-icon></div>
      <div class="section-line-item">
        <div class="info-icon-text">设置</div>
      </div>
    </div>
    <div class="section-line sub" (click)="changePassword()">
      <div class="section-line-item"><fa-icon class="info-icon" [icon]="faKey"></fa-icon></div>
      <div class="section-line-item">
        <div class="info-icon-text">修改密码</div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="section-line sub">
      <div class="section-line-item"><fa-icon class="info-icon" [icon]="faStickyNote"></fa-icon></div>
      <div class="section-line-item">
        <div class="info-icon-text">权限</div>
      </div>
    </div>
    <div class="section-line sub">
      <div class="section-line-item"><fa-icon class="info-icon" [icon]="faListAlt"></fa-icon></div>
      <div class="section-line-item">
        <div class="info-icon-text">未完成任务</div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="section-line sub" (click)="logout()">
      <div class="section-line-item"><fa-icon class="info-icon" [icon]="faDoorOpen"></fa-icon></div>
      <div class="section-line-item">
        <div class="info-icon-text">登出</div>
      </div>
    </div>
  </div>
</div>
