<form class="loginBox" [formGroup]="loginForm" >
  <div class="logoImg">
    <img src="../../assets/img/IPE.png">
    <div class="logoName">
      <span>国际精密</span><br/>
      <span>WMS</span><br/>
      <span>库存管理</span>
    </div>
  </div>

  <mat-form-field hideRequiredMarker>
    <input matInput placeholder="公司编号" formControlName="custCode" required >
    <mat-error *ngIf="custCode.invalid && (custCode.dirty || custCode.touched)">公司编号必填</mat-error>
  </mat-form-field>
  <mat-form-field hideRequiredMarker>
    <input matInput placeholder="用户名" formControlName="userName" required>
    <mat-error *ngIf="userName.invalid && (userName.dirty || userName.touched)">用户名必填</mat-error>
  </mat-form-field>
  <mat-form-field hideRequiredMarker>
    <input matInput type="password" placeholder="密码" formControlName="password" required>
    <mat-error *ngIf="password.invalid && (password.dirty || password.touched)">密码必填</mat-error>
  </mat-form-field>

    <mat-checkbox color="primary" formControlName="rememberMe">记住我</mat-checkbox>

  <div class="button-area">
    <button type="submit" mat-button [disabled]="!loginForm.valid" class="mat-flat-button mat-primary" (click)="login()">登入</button>
  </div>
  <br/>
  <br/>
  <br/>
  <br/>
  <div style="width: 100%; text-align: center">
    <a rel="nofollow" href="http://beian.miit.gov.cn/" target="_blank" style="text-decoration: none;font-size: small">粤ICP备18160091号</a>
    <div style="font-size: small">广州市新豪精密科技有限公司，版权所有 <span style="font-family:arial;">Copyright © 2020</span></div>
    <!-- <a rel="nofollow" href="/assets/app-wmspro-release.apk" style="text-decoration: none;font-size: small">APP下载</a> -->
  </div>
</form>

