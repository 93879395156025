import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {PurStockInDetails} from '../../master-model/pur-stock-in/PurStockInDetails';
import {LocalStorage}from '../../../utils/dataStore';


@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.scss']
})
export class TicketDetailComponent implements OnInit {
  LN='LN';

  @Input() ticket: PurStockInDetails;
  /**
   * 删除条码标签事件
   */
  @Output() deleteTicket = new EventEmitter();
  faTrashAlt = faTrashAlt;

  constructor(
  private lsdate:LocalStorage
    ){

     }

  ngOnInit() {
    
    this.getLN();
  
  
  }

  deleteCmd() {
    this.deleteTicket.emit();
    

  }
 getLN(){
 
  this.lsdate.setObject(this.LN,this.ticket.stklocation['locationName'])
  
 }
}
