import { Component, OnInit, NgZone, Inject, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogConfig,
  MatDialog,
} from "@angular/material/dialog";
import { PurStockInComponent } from "../../purchasing/pur-stock-in/pur-stock-in.component";
import { BarcodeService } from "../../../utilities/barcode.service";
import { HttpClient } from "@angular/common/http";
import { BusyService } from "../../../utilities/busy.service";
import { LocalStorage } from "../../../utils/dataStore";
import { MessageBoxComponent } from "src/app/message-box/message-box.component";

@Component({
  selector: "app-freight-dialog",
  templateUrl: "./freight-dialog.component.html",
  styleUrls: ["./freight-dialog.component.scss"],
})
export class FreightDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  locationCode: string;
  isOpen: boolean;
  subscriptions = [];
  errorMessage: string;
  showError: boolean;
  public locationType: string;
  ticket: any = null;

  constructor(
    private barcodeService: BarcodeService,
    private fb: FormBuilder,
    private http: HttpClient,
    private busy: BusyService,
    private dialogRef: MatDialogRef<PurStockInComponent>,
    private zone: NgZone,
    private dialog: MatDialog,
    private listdata: LocalStorage,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.showError = false;
    this.locationCode = "";
    this.ticket = data.ticket;
    this.locationType = data["locationType"];
    this.errorMessage = "货位错误，请重试";
    const sub = barcodeService.barcode.subscribe((val) => {
      // 由于 BehaviorSubject 在订阅那一刻会把当前值先发送一次，这部分是检查赋值时间和接收时间，如果超过
      // 一百毫秒则当作旧值，不做处理
      const now = +new Date();
      if (now - val.time < 100) {
        this.zone.run(() => {
          if (this.dialogRef) {
            this.form.controls["locationCode"].setValue(val.value);
          }
        });
      }
    });
    this.subscriptions.push(sub);
  }

  ngOnInit() {
    this.form = this.fb.group({
      locationCode: [this.locationCode, []],
    });
  }

  async save() {
    if (this.form.controls["locationCode"].value === "") {
      this.busy.setLoading(false);
      this.errorMessage = "输入为空请重输";
      this.showError = true;
      return;
    } else if (
      this.listdata.getObject("LN") === this.form.controls["locationCode"].value
    ) {
      this.busy.setLoading(false);
      this.errorMessage = "货位重复请重输";
      this.showError = true;
      return;
    }
    this.showError = false;
    let doc;
    this.busy.setLoading(true);
    if (this.locationType !== "K3") {
      try {
        doc = await this.http
          .get(
            sessionStorage.getItem("baseUrl") +
            "/loc/location/" +
            this.form.controls["locationCode"].value
          )
          .toPromise();
        if (
          doc.status === "success" &&
          doc.data.locationCode === this.form.controls["locationCode"].value
        ) {
          this.busy.setLoading(false);

          // 检查卡片组织是否与货位组织一致
          if (this.checkOrganization(this.ticket, doc.data) === false) {
            return;
          }
          console.log(doc.data.stkrack.stkarea[this.ticket["customMon"] ? 'customWh' : 'nonCustomWh'])
          console.log(this.ticket["stklocation"]["stkrack"]["stkarea"][this.ticket["customMon"] ? 'customWh' : 'nonCustomWh'])

          if (doc.data.stkrack.stkarea[this.ticket["customMon"] ? 'customWh' : 'nonCustomWh'] != this.ticket["stklocation"]["stkrack"]["stkarea"][this.ticket["customMon"] ? 'customWh' : 'nonCustomWh']) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
              title: "错误",
              text: "物料与货位仓库编码不一致，无法进行货位转移",
            };
            this.dialog.open(MessageBoxComponent, dialogConfig);
            return;
          }

          this.dialogRef.close({
            data: doc.data,
            submit: true,
          });
        } else {
          this.busy.setLoading(false);
          this.errorMessage =
            "找不到货位" + this.form.controls["locationCode"].value;
          this.showError = true;
        }
      } catch (e) {
        this.busy.setLoading(false);
        this.errorMessage = "无法找到该货位";
        this.showError = true;
      }
    } else {
      this.busy.setLoading(false);
      this.dialogRef.close({
        data: this.form.controls["locationCode"].value,
        submit: false,
      });
    }
  }

  close() {
    this.dialogRef.close({ submit: false });
  }

  ngOnDestroy(): void {
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }

  /** 检查仓位与贴纸标签是否归属同一组织 */
  private checkOrganization(ticket: { stkOrganizationCode: string, stkOrganizationName: string }, locatioin: { OrganizationCode: string, OrganizationName: string }) {
    if (ticket.stkOrganizationCode === locatioin.OrganizationCode) {
      return true;
    }
    this.errorMessage = `标签所属组织 ${ticket.stkOrganizationName} 与仓位所属组织 ${locatioin.OrganizationName} 不一致`;
    this.showError = true;
    return false;
  }
}
