<div>
  <div class="row">
    <fa-icon class="delete-btn" [icon]="faTrashAlt" (click)="deleteCmd()"></fa-icon>
    <input readonly class="UUID" [value]="ticket.id">

    <div class="QTY">{{ticket.current_qty | number: "1.0-6"}}</div>
  </div>
  <div class="content">
    <div>
      <span>编号: </span>
      <span>{{ticket.invCode}}</span>
    </div>
    <div>
      <span>是否保税： </span>
      <span *ngIf="ticket.customMon === true" style="font-weight: bold">保税</span>
      <span *ngIf="ticket.customMon === false" style="font-weight: bold">国内</span>
    </div>
    <div>
      <span>规格型号: </span>
      <span>{{ticket.invStd}}</span>
    </div>
    <div>
      <span>公差: </span>
      <span>{{ticket.invTor}}</span>
    </div>
    <div>
      <span>批次: </span>
      <span>{{ticket.invBatch}}</span>
    </div>
    <div>
      <span>当前数量: </span>
      <span>{{ticket.current_qty}}</span>
    </div>
    <div *ngIf="!ticket.type">
      <span>当前区域：</span>
      <span>{{ticket.stklocation['stkrack']['stkarea']['areaName']}}</span>
    </div>
    <div *ngIf="!ticket.type">
      <span>当前货位：</span>
      <span>{{ticket.stklocation['locationName']}}</span>
    </div>
    <div *ngIf="!ticket.type">
      <span>推荐货位：</span>
      <span>{{ticket.recommendLoc}}</span>
    </div>
  </div>
</div>
