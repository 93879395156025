import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-button-print-renderer',
  // tslint:disable-next-line:max-line-length
  template: '<button class="{{btnClass}}" type="button" ngxPrint printTitle="打印预览" printSectionId="print_media" styleSheetFile="/assets/css/ticketPrint.css">打印</button>',
})
export class ButtonPrintRendererComponent implements ICellRendererAngularComp {

  params: any;
  btnClass: string;

  constructor() { }

  agInit(params): void {
    this.params = params;
    this.btnClass = this.params.btnClass || 'mat-button';
  }

  refresh(params: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data,
      };
      this.params.onClick(params);
    }
  }
}
