import { Component, OnInit } from '@angular/core';
import {BusyService} from '../busy.service';

@Component({
  selector: 'app-busy-indicator',
  templateUrl: './busy-indicator.component.html',
  styleUrls: ['./busy-indicator.component.scss']
})
export class BusyIndicatorComponent implements OnInit {

  show = false;

  constructor(private busyService: BusyService) { }

  ngOnInit() {
    this.busyService.isBusy.subscribe(busy => {
      this.show = busy;
    });
  }
}
