import {Component, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PurStockInComponent} from '../../purchasing/pur-stock-in/pur-stock-in.component';
import {BarcodeService} from '../../../utilities/barcode.service';
import {HttpClient} from '@angular/common/http';
import {BusyService} from '../../../utilities/busy.service';

@Component({
  selector: 'app-qty-convert-dialog',
  templateUrl: './qty-convert-dialog.component.html',
  styleUrls: ['./qty-convert-dialog.component.scss']
})
export class QtyConvertDialogComponent implements OnInit {

  form: FormGroup;
  ticketObj: any;

  get qty() { return this.form.get('qty'); }
  get qty_sec() { return this.form.get('qty_sec'); }

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>
  ) {
    this.ticketObj = data;
  }

  ngOnInit() {
    this.form = this.fb.group({
      qty_sec: [this.data.qty_sec, [Validators.required]],
      qty: [this.data.qty, [Validators.required]],
    });
    this.form.setValue({
      qty_sec: this.data.qty_sec ? this.data.qty_sec : 0,
      qty: this.data.qty ? this.data.qty : 0,
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close(null);
  }
}
