import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NfcService {
  private nfcIDText = {time: 0, value: ''};

  public nfcID: BehaviorSubject<any> = new BehaviorSubject(this.nfcIDText);

  constructor() {
    window['onRFID'] = (text) => {
      this.nfcID.next({time: +new Date(), value: text});
    };
    window['onNFC'] = (text) => {
      this.nfcID.next({time: +new Date(), value: text});
    };
  }
}
