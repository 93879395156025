import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * 条码终端物理扫描按钮接口，从 SDK 和 APP 内触发 onBarCode 函数用。
 */
@Injectable({
  providedIn: 'root'
})
export class BarcodeService {

  private barcodeText = { time: 0, value: '' };
  private $barcode: BehaviorSubject<{ time: number; value: string; }> = new BehaviorSubject(this.barcodeText);
  /**
   * 条码 publisher，给各组件注册订阅用
   */
  public get barcode() {
    return this.$barcode.asObservable();
  }
  /**
   * 构造函数，注册全局函数 onBarCode，如触发，则对订阅发送扫描到的，已解析的条码字符
   */
  constructor() {
    window['onBarCode'] = (text: string) => {
      this.$barcode.next({ time: +new Date(), value: text });
    };
  }
}


