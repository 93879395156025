<app-bottom-nav></app-bottom-nav>
<div class="setting-container animated fadeInUp">
  <p>系统设置需管理员权限</p>
  <div class="section">
    <div class="section-line sub">
      <div class="section-line-item"><fa-icon class="info-icon" [icon]="faCog"></fa-icon></div>
      <div class="section-line-item">
        <div class="info-icon-text">系统设置</div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="section-line sub" (click)="getMac()">
      <div class="section-line-item"><fa-icon class="info-icon" [icon]="faUpload"></fa-icon></div>
      <div class="section-line-item">
        <div class="info-icon-text" >版本更新（版本号：v1.2）</div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="section-line sub">
      <div class="section-line-item"><fa-icon class="info-icon" [icon]="faBook"></fa-icon></div>
      <div class="section-line-item">
        <div class="info-icon-text">操作说明</div>
      </div>
    </div>
    <div class="section-line sub">
      <div class="section-line-item"><img src="/assets/img/IPE.png"/></div>
      <div class="section-line-item">
        <div class="info-icon-text">关于 IPE WMS</div>
      </div>
    </div>
  </div>
</div>
