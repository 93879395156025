import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({ providedIn: 'root' })
export class SnackBarService {

    constructor(private snack: MatSnackBar) { }

    /** 显示 3 秒 */
    short(message: string) {
        this.snack.dismiss();
        this.snack.open(message, '关闭', { duration: 3000 });
    }

    /** 显示 6 秒 */
    mid(message: string) {
        this.snack.dismiss();
        this.snack.open(message, '关闭', { duration: 6000 });
    }

    /** 显示 9 秒 */
    long(message: string) {
        this.snack.dismiss();
        this.snack.open(message, '关闭', { duration: 9000 });
    }

    /** 固定，不自动关闭 */
    sticky(message: string) {
        this.snack.dismiss();
        this.snack.open(message, '关闭');
    }
}