import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { BottomNavComponent } from './bottom-nav/bottom-nav.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TopnavComponent } from './topnav/topnav.component';
import { LocationDialogComponent } from './location-dialog/location-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { QtyConvertDialogComponent } from './qty-convert-dialog/qty-convert-dialog.component';
import { ButtonRendererComponent } from './button-renderer/button-renderer.component';
import { ButtonPrintRendererComponent } from './button-print-renderer/button-print-renderer.component';
import { NgxPrintModule } from 'ngx-print';
import { ToastDialogComponent } from './toast-dialog/toast-dialog.component';
import { FreightDialogComponent } from "./freight-dialog/freight-dialog.component";
import { MatBadgeModule } from '@angular/material/badge';
import { OrgSelectComponent } from './org-select/org-select.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    RouterModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    NgxPrintModule,
    MatBadgeModule,
    MatSelectModule
  ],
  declarations: [
    TicketDetailComponent,
    ConfirmationDialogComponent,
    BottomNavComponent,
    TopnavComponent,
    LocationDialogComponent,
    ChangePasswordDialogComponent,
    QtyConvertDialogComponent,
    ButtonRendererComponent,
    ButtonPrintRendererComponent,
    FreightDialogComponent,
    ToastDialogComponent,
    OrgSelectComponent
  ],
  exports: [
    TicketDetailComponent,
    ConfirmationDialogComponent,
    BottomNavComponent,
    TopnavComponent,
    LocationDialogComponent,
    ChangePasswordDialogComponent,
    QtyConvertDialogComponent,
    ButtonRendererComponent,
    ButtonPrintRendererComponent,
    ToastDialogComponent,
    OrgSelectComponent
  ],
})
export class CommonUiModule { }
