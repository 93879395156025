/**
 * 采购入库单标体
 * Created by Hua on 22/10/2018.
 */

export class PurStockInDetails {
  /**
   * 条码标签唯一标识
   */
  id: string;

  /**
   * 产线编号
   */
  tu: string;

  /**
   * 物料编码
   */
  invCode: string;

  /**
   * 物料名称
   */
  invName: string;

  /**
   * 规格
   */
  invStd: string;

  /**
   * 推荐货位
   */
  recommendLoc: string;

  /**
   * 公差
   */
  invTor: string;

  /**
   * 箱数
   */
  boxCount: number;

  /**
   * 箱号
   */
  boxIndex: number;

  /**
   * 批号
   */
  invBatch: string;

  /**
   * 入库日期
   */
  stkInDate: number;

  /**
   * 是否监管物料
   */
  customMon: boolean;

  /**
   * 当前主单位数量
   */
  current_qty: number;

  /**
   * 当前辅单位数量
   */
  current_sec_qty: number;

  /**
   * 当前货位对象
   */
  stklocation: object;

  /**
   * 标签交易明细
   */
  stktickettrans: any;

  /**
   * 标签是否活跃、能操作
   */
  stkactive: boolean;

  /**
   * 炉号
   */
  stkoven: string;

  /**
   * 供应商编号
   */
  stkSupplierCode: string;

  /**
   * 发货数量，不存服务器
   */
  dis_qty: number;

  /**
   * 发货辅单位数量，不存服务器
   */
  dis_qty_sec: number;

  invVerCode: string;

  // 1 代表是汇总码打印
  type:number;

  constructor() {}
}
