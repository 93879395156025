import { Component, OnInit } from '@angular/core';
import {faBook, faCog, faUpload} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  faBook = faBook;
  faCog = faCog;
  faUpload = faUpload;
  mac: any;
  constructor(
    private window: Window,
    private router: Router,
    private authService: AuthService,

  ) {
  }

  ngOnInit() {
  }

  async getMac() {
    this.window['ANDROID'].doClean();
    this.authService.logout();
    this.router.navigate(['/']);
  }
}
