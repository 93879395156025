import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurStockIn } from './pur-stock-in/PurStockIn';
import { PurStockInDetails } from './pur-stock-in/PurStockInDetails';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  exports: [
   /* PurStockIn,
    PurStockInDetails*/
  ]
})
export class MasterModelModule { }

export * from './pur-stock-in/PurStockInDetails';
export * from './pur-stock-in/PurStockIn';


