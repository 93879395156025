<div class="top-sidenav-container">
  <mat-toolbar color="primary" class="sidenav-toolbar">
    <a
      class="icon-center"
      routerLink="/core/msg/dash"
      routerLinkActive="glowing"
      (click)="getCount()"
    >
      <fa-icon [icon]="faComment"></fa-icon>
      <span>消息</span>
      <!-- <span class="badge" *ngIf="messageCount > 0">{{messageCount > 99 ? '99+' : messageCount}}</span> -->
    </a>
    <mat-badge
      class="firstBadge"
      [matBadge]="messageCount > 99 ? '99+' : messageCount"
      matBadgeColor="warn"
      [matBadgeHidden]="messageCount == 0"
    ></mat-badge>
    <a
      class="icon-center"
      routerLink="/core/dashboard"
      routerLinkActive="glowing"
      (click)="getCount()"
    >
      <fa-icon [icon]="faHome"></fa-icon>
      <span>功能</span>
    </a>
    <mat-badge
      class="firstBadge"
      [matBadge]="functionNum > 99 ? '99+' : functionNum"
      matBadgeColor="warn"
      [matBadgeHidden]="functionNum == 0"
    ></mat-badge>
    <a
      class="icon-center"
      routerLink="/core/user-info"
      routerLinkActive="glowing"
    >
      <fa-icon [icon]="faUser"></fa-icon>
      <span>我</span>
    </a>
    <a
      class="icon-center"
      routerLink="/core/settings"
      routerLinkActive="glowing"
    >
      <fa-icon [icon]="faCog"></fa-icon>
      <span>设置</span>
    </a>
  </mat-toolbar>
</div>
